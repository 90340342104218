<template>
  <div class="ecg-container">
    <p class="title">心脑无忧</p>
    <div class="action-bar">
        <Button @click="$router.push({name: 'ECGSummary'})">添加动态心脏生理行为异常数据汇总</Button>
        <Button @click="$router.push({name: 'ECGPhysiology'})">添加心电生理数据</Button>
        <Button @click="$router.push({name: 'ECGBehavior'})">添加行为记录单</Button>
    </div>
    <Table :columns="columns" :data="list"></Table>
    <Row>
        <Col span="12"><div class="table-oprate"></div></Col>
        <Col span="12">
            <span class="records">共{{pageTotal}}条记录</span>
        </Col>
    </Row>
    <div class="page-nations-wrapper">
        <div class="page-nations">
            <Page :total="pageTotal" :current="searchForm.page" :page-size="searchForm.size" @on-change="changePage"></Page>
        </div>
    </div>
  </div>
</template>

<script>
import MemberService from '@/services/memberService';

export default {
	data() {
		return {
			pageTotal: 0,
			searchForm: {
				page: 1,
				size: 10,
				member_id: this.$route.params.member_id,
			},
			columns: [
				{ title: '日期', key: 'rq', align: 'center' },
				{ title: '项目', key: 'xm', align: 'center' },
				{
					title: '操作',
					align: 'center',
					render: (h, params) => {
						return h('div', [
							h(
								'a',
								{
									class: 'icon_eye',
									style: {
										margin: '0 10px',
									},
									on: {
										click: () => {
											this.goToDetail(params.row);
										},
									},
								},
								'查看',
							),
						]);
					},
				},
			],
			list: [],
		};
	},
	created() {
		this.getList();
	},
	methods: {
		changePage(page) {
			this.searchForm.page = page;
			this.getList();
		},
		getList() {
			MemberService.getECGList(this.searchForm).then((data) => {
				this.list = data.list;
				this.pageTotal = data.row_size;
			});
		},
		goToDetail(data) {
			switch (data.xm) {
				case '心电生理数据':
					this.$router.push({
						name: 'ECGPhysiologyPreview',
						params: { record_id: data.id },
					});
					break;
				case '行为记录单':
					this.$router.push({
						name: 'ECGBebaviorPreview',
						params: { record_id: data.id },
					});
					break;
				default:
					this.$router.push({
						name: 'ECGSummaryPreview',
						params: { record_id: data.id },
					});
			}
		},
	},
};
</script>

<style lang="less" scoped>
.ecg-container {
    background-color: #fff;
    padding: 15px;
    margin-top: 10px;
    .title {
        text-align: center;
        margin: 10px 0;
        font-size: 22px;
    }
    .action-bar {
        margin: 20px;
        display: flex;
        justify-content: space-evenly;
    }
}
</style>
